import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FrontendFormElementInput } from '../../formelementinput.class';

@Component({
  selector: 'app-inner-label',
  templateUrl: './inner-label.component.html',
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InnerLabelComponent), multi: true}
  ]
})
export class InnerLabelComponent extends FrontendFormElementInput {

  value = '';

  writeValue(obj: any): void {
    this.value = obj;
    this.propagateChange(this.value);
  }
}
