import { EventEmitter, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Md5 } from 'ts-md5/dist/md5';
import { Translation } from '../models/translation';
import { TranslatorDatabaseService } from './rest-translator-database.service';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { DestroyableObjectTrait } from '../../../shared/utils/destroyableobject.trait';
import { ClientCache } from '../../clientcache/ClientCacheClass';
import { isNullOrWhitespace, nameof, UtilsTypescript } from '../../../shared/utils/typescript.utils';
import { AppConfigurationService } from '../../../app.configuration.service';
import { AppContextService } from '../../../shared/context/context.service';
import { LocaleService } from '../../services/ETG_SABENTISpro_Application_Core_locale.service';
import {
  AuthenticationStatusEnums,
  CoreLocaleLanguageKeys, ILanguageSessionWrapper,
  ISessionInfo,
  LocaleTarget
} from '../../models/ETG_SABENTISpro_Application_Core_models';
import { SessionstateService } from '../../sessionstate/sessionstate.service';

@Injectable({
  providedIn: 'root'
})
export class TranslatorService extends DestroyableObjectTrait {

  /**
   * This is the default language of the app.
   */
  private activeLanguage: string;

  /*
  * Ths ISO code (short-form) of the angularLanguage
  * */
  private angularLanguage: string;

  /**
   * En el modo record, para evitar solapamiento de peticiones
   *
   * @private
   */
  private postTranslateObservables: { [id: string]: Observable<void> } = {};

  /**
   *
   * @private
   */
  private syncLanguageLiteralsObservables: { [id: string]: Observable<void> } = {};

  /**
   * Para poder hacer que el servicio de traduccione funcione de manera síncrona, hay que almacenar
   * una copia en memoria de todas las traducciones. Por defecto el localdb es asíncrono, además
   * hacerlo así también debería mejorar el rendimiento.
   *
   * @private
   */
  private translationIndex: { [language: string]: { [id: string]: Translation } } = {};

  /**
   * Activar el modo grabación de literales
   *
   * @private
   */
  private recordMode: boolean = false;

  constructor(
      private db: TranslatorDatabaseService,
      private localeService: LocaleService,
      protected clientCache: ClientCache,
      private configService: AppConfigurationService,
      private contextService: AppContextService,
      private sessionStateService: SessionstateService
  ) {
    super();

    this.sessionStateService
        .$sessionDataPropertyChanged<ILanguageSessionWrapper>([nameof<ILanguageSessionWrapper>('CurrentLanguage')])
        .subscribe((sessionData) => {
          this.setActiveLanguage(sessionData.CurrentLanguage);
          this.setAngularLanguage(sessionData.CurrentLanguageIsoCode);
        });

    configService.bootstrapDone$
        .subscribe(
            () => {
              // Al arrancar la plataforma pedimos todas las traducciones
              // TODO: El borrado debería ser un mecanismo de emergencia, queremos persistencia entre recargas, y más
              // porque se pueden obtener los cambios de manera diferencial (changedAt)
              // translatorService.clear();
              this.recordMode = configService.getBootstrapData('locale-record-mode');

              contextService.contextChanged
                  .subscribe((context: { [id: string]: string }) => {
                        this.setActiveLanguage(context['userLanguage']);
                        this.setAngularLanguage(context['userLanguageIso']);
                      }
                  );
            }
        );
  }

  getIdentifier(context: string, literal: string, language: string): string {
    return Md5.hashStr(context + '::' + literal + '::' + language)
        .toString()
        .toUpperCase();
  }

  setActiveLanguage(lang: string): this {
    if (this.activeLanguage === lang || !lang) {
      return;
    }
    this.activeLanguage = lang;
    this.syncLanguageLiterals(this.activeLanguage).subscribe();
    return this;
  }

  setAngularLanguage(lang: string): void {
    if (!lang && !this.angularLanguage) {
      this.angularLanguage = 'es';
    } else if (lang) {
      let isoShort = lang.split('-')[0];
      this.angularLanguage = isoShort;
    }
  }

  /**
   * TODO: Los idiomas de backend no tienen porque se compatibles con códigos
   * de idioma oficiales!
   */
  getDefaultLang(): string {
    return this.activeLanguage;
  }

  /**
   * Returns the language that angular should use (short ISO code)
   */
  getAngularLang(): string {
    return this.angularLanguage;
  }

  getDefaultContext(): string {
    return 'ui:default';
  }

  /**
   *
   * @param literal
   * @param replacements
   * @param context
   */
  get(literal: string, replacements: { [id: string]: string } = {}, context: string = null): string {

    const language: string = this.activeLanguage;
    context = context ?? this.getDefaultContext();

    if (language == null || language === CoreLocaleLanguageKeys.NoTranslationLanguageCode) {
      return this.applyReplacements(literal, replacements);
    }

    if (language === CoreLocaleLanguageKeys.TagsLanguageCode) {
      return ('|' + context + ' ' + literal + '|');
    }

    return this.tLocal(literal, replacements, language, context);
  }

  /**
   * Do a translation directly from the database
   *
   * @param literal
   * @param replacements
   * @param context
   */
  private tLocal(literal: string, replacements: {
    [id: string]: string
  }, language: string, context: string = null): string {

    const key: string = this.getIdentifier(context, literal, language);
    const defaultKey: string = this.getIdentifier(this.getDefaultContext(), literal, language);

    // Buscamos en la memoria local
    if (!this.translationIndex.hasOwnProperty(language)) {
      console.warn('No hay cargadas traducciones para el idioma ' + language);
    }

    let translation: Translation = null;

    if (this.translationIndex[language] && this.translationIndex[language][key]) {
      translation = this.translationIndex[language][key];
    } else if (this.translationIndex[language] && this.translationIndex[language][defaultKey]) {
      translation = this.translationIndex[language][defaultKey];
    }

    // Esto solo es para registrar en backend la traducción, la respuesta que obtengamos nos importa nada
    // porque si no lo tenemos en local, es que no está.
    if (!translation) {
      if (this.recordMode === true) {
        this.translateRemoteSingle(literal, language, context)
            .subscribe();
      }

      // Creamos una entrada ficticia en la memoria local, de todos modos no está
      // traducido en remoto
      translation = new Translation(this.getIdentifier(defaultKey, literal, language),
          language,
          literal);

      this.translationIndex[language] = this.translationIndex[language] ?? {};
      this.translationIndex[language][translation.key] = translation;
    }

    let literalTranslated: string = translation.literalTranslated;
    if (literalTranslated === CoreLocaleLanguageKeys.MissingTargetValue) {
      literalTranslated = literal;
    } else if (literalTranslated === CoreLocaleLanguageKeys.SameAsSourceTargetValue) {
      literalTranslated = literal;
    }

    return this.applyReplacements(literalTranslated, replacements);
  }

  /**
   * Obtiene una traducción individual remota. Se usa solo en el modo recolección de literales.
   *
   * @param literal
   * @param language
   * @param context
   * @private
   */
  private translateRemoteSingle(literal: string, language: string, context: string): Observable<void> {

    const key: string = this.getIdentifier(context, literal, language);

    if (key in this.postTranslateObservables) {
      return this.postTranslateObservables[key];
    }

    const emitter: EventEmitter<void> = new EventEmitter<void>();
    this.postTranslateObservables[key] = emitter;

    return this.localeService
        .postLocale(literal, language, context, {showSpinner: false})
        .pipe(
            map((i) => {
              this.upsertTranslationTargets([i.result]);
            }),
            finalize(() => {
              delete this.postTranslateObservables[key];
            })
        );
  }

  /**
   * Sincroniza todos los literales de un idioma determinado a la base de datos local.
   *
   * @param language
   * @private
   */
  private syncLanguageLiterals(language: string): Observable<void> {

    if (UtilsTypescript.isNullOrWhitespace(language)) {
      throw new Error('language must have a value.');
    }

    if (this.syncLanguageLiteralsObservables.hasOwnProperty(language)) {
      return this.syncLanguageLiteralsObservables[language];
    }

    /*const cacheKey: string = 'locale-sync-lang-' + language;
    const lastSync: number = this.clientCache.getItem(cacheKey, 0);*/

    // De momento hacemos sincronización total, el problema de la sincronización parcial
    // es que solo somos capaces de añadir traducciones, no de quitarlas.
    const lastSync: number = 0;

    // Si han pasado menos de 60 segundos desde la última sincronización, no sincronizamos nada. La sincronización
    // es ligera porque es diferencial, pero aún y así no queremos taladrar el endpoint. Actualizar
    // automáticamente cada 5 minutos
    if (new Date().getTime() - lastSync < 300) {
      return of(null);
    }

    this.syncLanguageLiteralsObservables[language] = this.localeService
        .getLocalebycontext(language, 'ui:', lastSync, {showSpinner: false})
        .pipe(
            switchMap((i) => {
              // Si es una sincronización parcial, no borro las traducciones existentes.
              if (lastSync > 0) {
                return of(i);
              }
              return this.db.clearTranslationsForLanguage(language)
                  .pipe(
                      tap((j) => console.debug(`Deleted ${j} stored translations for language ${language}`)),
                      map(() => i)
                  );
            }),
            switchMap((i) => {
              return this.upsertTranslationTargets(i.result)
                  .pipe(
                      tap(() => {
                        console.debug(`Retrieved ${i.result?.length} translations from server for language ${language}`)
                        // this.clientCache.setItem(cacheKey, new Date().getTime(), 'persistent');
                      }),
                      switchMap(() => {
                        return this.updateMemoryCacheFromDatabase(language);
                      })
                  );
            }),
            finalize(() => {
              delete this.syncLanguageLiteralsObservables[language];
            })
        );

    return this.syncLanguageLiteralsObservables[language];
  }

  /**
   * Para un idioma concreto, actualiza todos los literales de la caché
   * de memoria con los que hay en base de datos.
   *
   * @param language
   * @private
   */
  private updateMemoryCacheFromDatabase(language: string): Observable<void> {
    return this.db.getTranslationsForLanguage(language)
        .pipe(
            map((i) => {
              this.translationIndex = this.translationIndex ?? {};
              this.translationIndex[language] = {};
              for (const t of i) {
                this.translationIndex[language][t.key] = t;
              }
            })
        );
  }

  clear(): void {
    this.db.clear();
  }

  /**
   * Aplica reemplazos a un literal
   *
   * @param literal
   * @param params
   */
  applyReplacements(literal: any, params: { [id: string]: string }): string {
    if (!params) {
      return literal;
    }
    Object.keys(params).forEach(key => {
      if (isNullOrWhitespace(literal)) {
        console.debug('Empty literal going through translation service.');
        return;
      }
      literal = literal.replace(
          new RegExp(key, 'g'),
          params[key]?.toString() ?? ''
      );
    });
    return literal;
  }

  /**
   * Actualiza en la persistencia local todos los literales de un idioma determinado
   *
   * @param targets
   * @private
   */
  private upsertTranslationTargets(targets: LocaleTarget[]): Observable<void> {
    const translations: Translation[] = [];
    this.translationIndex = this.translationIndex ?? {};
    for (const target of targets) {
      this.translationIndex[target.language_translation] = this.translationIndex[target.language_translation] ?? {};
      const translationItem: Translation = new Translation(
          target.key,
          target.language_translation,
          target.literal_translation
      );
      this.translationIndex[translationItem.targetLanguage][target.key] = translationItem;
      translations.push(translationItem);
    }
    return this.db.putTranslations(translations).pipe(map(() => {
    }));
  }
}
