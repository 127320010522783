import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { RestTranslatorModule } from '../../core/translator/rest-translator.module';
import { GridModule } from './grid/grid.module';
import { List2Component } from './list.component';
import { PagerModule } from './pagers/pager.module';
import { ViewsSharedModule } from './shared/views-shared.module';
import { ViewHeadersModule } from './view-headers/view-headers.module';
import { ListLoadingPlaceholderComponent } from './list-loading-placeholder/list-loading-placeholder.component';
import { CommentModule } from '../comment/comment.module';
import { CoreModule } from '../../core/core.module';
import { FuseWidgetModule } from '../../../@fuse/components';

@NgModule({
  imports: [
    CommonModule,
    RestTranslatorModule,
    GridModule,
    PagerModule,
    FormsModule,
    ViewsSharedModule,
    ViewHeadersModule,
    CommentModule,
    CoreModule,
    FuseWidgetModule
  ],
  declarations: [
    List2Component,
    ListLoadingPlaceholderComponent,
  ],
  exports: [
    List2Component,
  ],
  providers: [
    DatePipe
  ]
})
export class List2Module {
  constructor() {
  }
}
