import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { RestTranslatorModule } from '../core/translator/rest-translator.module';
import { SFormModule } from '../shared/form/form.module';
import { SharedModule } from '../shared/shared.module';
import { AccessComponent } from './access/access.component';
import { EditProfileRoutingModule } from './editProfile-routing.module';
import { PersonalDataComponent } from './personalData/personalData.component';

@NgModule({
  imports: [
    SFormModule,
    CommonModule,
    SharedModule,
    EditProfileRoutingModule,
    RestTranslatorModule
  ],
  declarations: [
    AccessComponent,
    PersonalDataComponent
  ],
  exports: [ AccessComponent ]
})

export class EditProfileModule {
}
