import { Component, ViewEncapsulation } from '@angular/core';
import {
  ViewFilterTriggerComponent
} from '../../../view-headers-shared/filters/components/view-filter-trigger.component';
import { ListComponent2Service } from '../../../../list.service';
import { DateTimeService } from '../../../../../../core/date-time/date-time.service';
import { DecoupledModalBridgeService } from '../../../../../decoupled-modal/decoupled-modal-bridge.service';
import { TranslatorService } from '../../../../../../core/translator/services/rest-translator.service';
import { fuseAnimations } from '../../../../../../../@fuse/animations';
import { backendTypeMatch } from '../../../../../utils/typescript.utils';
import { ViewHeaderMaterial } from '../../../../../../core/models/ETG_SABENTISpro_Application_Core_models';

@Component({
  selector: 'app-view-filter-material',
  templateUrl: './view-filter-material.component.html',
  styleUrls: ['./view-filter-material.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class ViewFilterMaterialComponent extends ViewFilterTriggerComponent {

  constructor(
      protected listComponentService: ListComponent2Service,
      protected dateTimeService: DateTimeService,
      protected dmbs: DecoupledModalBridgeService,
      protected translatorService: TranslatorService
  ) {
    super(listComponentService, dateTimeService, dmbs, translatorService);
  }

  /**
   * Hide the button Add filter button
   */
  get hideAddFilterButton(): boolean {
    if (backendTypeMatch(ViewHeaderMaterial.$type, this.listComponentService.getConfiguration().ViewHeader)) {
      return (this.listComponentService.getConfiguration().ViewHeader as ViewHeaderMaterial)?.HideAddFilterToViewButton ?? false;
    }
    return false;
  }
}
